@import "~@ng-select/ng-select/themes/default.theme.css";
html,
body {
  height: 100%;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

img {
  max-width: 100%;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-24 {
  font-size: 24px;
}

.f-30 {
  font-size: 30px;
}

.f-36 {
  font-size: 36px;
}

.f-46 {
  font-size: 46px;
}

.w-md-50 {
  width: 50% !important;
}

.font-weight-semi-bold {
  font-weight: 500 !important;
}

a {
  color: #374abe;
}

a,
a:hover {
  text-decoration: none;
  outline: none;
}

.btn:focus,
.form-control:focus {
  box-shadow: none !important;
}

ul {
  list-style: none;
}

.cursor-p {
  cursor: pointer;
}

.form-control {
  box-shadow: none !important;
}

.text-black {
  color: #000;
}

.text-indigo {
  color: #374abe;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 0.5;
  font-size: 13px;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 0.5;
  font-size: 13px;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 0.5;
  font-size: 13px;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 0.5;
  font-size: 13px;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 0.5;
  font-size: 13px;
}

body {
  background: #f2f5ff;
}

.text-center {
  text-align: center;
}

.bg-gray-lt {
  background: #eee;
}

.bg-primary-lt {
  background: #edf2fd;
}

.side-bar {
  width: 250px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  overflow: hidden;
}

.rounded-2 {
  border-radius: 15px;
}

.rounded-top-2 {
  border-radius: 15px 15px 0 0;
}

.rounded-bottom-2 {
  border-radius: 0 0 15px 15px;
}

.page-container {
  margin: 75px 0 0 250px;
  padding: 40px;
  position: relative;
}

.semi-bold {
  font-weight: 500;
}

.menu-container {
  overflow-y: auto;
  height: calc(100% - 76px);
  margin-right: -28px;
  padding-right: 20px;
}

.main-menu {
  padding: 0;
  margin: 0;
}
.main-menu li a {
  color: #000;
  font-size: 13px;
  font-weight: 600;
  display: block;
  padding: 10px 8px;
  border-radius: 10px;
  margin-bottom: 5px;
  transition: all ease-in-out 0.4s;
}
.main-menu li a:hover, .main-menu li a.active {
  color: #fff;
  background: #374abe;
  transition: all ease-in-out 0.2s;
}
.main-menu li a span {
  margin-left: 8px;
}
.main-menu li .sub-menu li a {
  padding: 3px;
  background: none;
}
.main-menu li .sub-menu li a:hover, .main-menu li .sub-menu li a.active {
  color: #fff;
  background: #374abe;
  transition: all ease-in-out 0.2s;
}

.header-nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 250px;
  background: #f2f5ff;
  z-index: 9;
}

.rounde-name {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #f0f0f0;
  border-radius: 50%;
  color: #374abe;
  line-height: 50px;
}

.btn-outline-primary {
  border-color: #374abe;
  color: #374abe;
  font-weight: bold;
  font-size: 14px;
}
.btn-outline-primary:hover {
  background: #374abe;
  border-color: #374abe;
}

.btn-primary {
  border-color: #374abe;
  background: #374abe;
  font-weight: bold;
  font-size: 14px;
}

.btn-default {
  border-color: #f0f0f0;
  background: #f0f0f0;
  font-weight: bold;
  font-size: 14px;
}
.btn-default:hover {
  border-color: #ddd;
  background: #ddd;
}

.steper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  background: url("../../assets/img/tick-gray.svg") no-repeat center center;
}

.custom-tab .nav-item .nav-link {
  position: relative;
  margin-right: 50px;
  background: #fff;
  border-radius: 10px !important;
  padding: 20px;
  border: none !important;
}
.custom-tab .nav-item .nav-link:hover {
  border: none !important;
  border-bottom: 2px solid #374abe !important;
  border-radius: 10px 10px 0 0 !important;
}
.custom-tab .nav-item .nav-link:after {
  content: "";
  background: #ddd;
  height: 2px;
  width: 50px;
  position: absolute;
  left: 100%;
}
.custom-tab .nav-item .nav-link.completed {
  border: none !important;
  border-bottom: 2px solid transparent !important;
  border-radius: 10px 10px 0 0 !important;
}
.custom-tab .nav-item .nav-link.completed .steper {
  background: url("../../assets/img/tick-blue.svg") no-repeat center center;
}
.custom-tab .nav-item .nav-link.active {
  border: none !important;
  border-bottom: 2px solid #374abe !important;
  border-radius: 10px 10px 0 0 !important;
}
.custom-tab .nav-item .nav-link.active .steper {
  background: url("../../assets/img/tick-blue.svg") no-repeat center center;
}
.custom-tab .nav-item:last-child a:after {
  content: none;
}

.custom-control-label {
  line-height: 23px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #27c79a;
  background-color: #27c79a;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #374abe;
  background-color: #374abe;
}

.table thead {
  background: #eee;
}
.table thead th {
  border: none;
  color: #000;
}
.table .selected {
  background: #c12a2b;
}
.table .selected td {
  color: #fff;
}
.table .selected td a {
  color: #fff;
}
.table .selected.accepted {
  background: #fff;
}
.table .selected.accepted td {
  color: #000;
}
.table .selected.accepted td a {
  color: #000;
}
.table .selected.accepted td .edit-order {
  display: none;
}
.table .selected.accepted .hideItem {
  display: none;
}

.filter-dropdown {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  z-index: 999;
}
.filter-dropdown .filter-inner {
  background: #fff;
  border-radius: 10px;
  padding: 40px;
}
.filter-dropdown:before {
  content: "";
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.open-filter .filter-dropdown {
  display: block;
}

.label {
  font-weight: 600;
}

label.border-dashed {
  position: relative;
}

.add-count input {
  width: 30px;
  text-align: center;
  border: none;
}
.add-count .plus,
.add-count .minus {
  cursor: pointer;
  width: 24px;
  display: inline-block;
  text-align: center;
  font-size: 30px;
  line-height: 1;
}
.add-count .plus {
  font-size: 20px;
}

.shadow-custom {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.shadow-custom-2 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.upload-img-wrap {
  width: 70px;
  height: 60px;
  border-radius: 5px;
  display: inline-block;
  vertical-align: top;
  margin: 8px;
  position: relative;
}
.upload-img-wrap > img {
  width: 100%;
  height: 100%;
}
.upload-img-wrap .close-img {
  text-align: center;
  position: absolute;
  bottom: -18px;
  cursor: pointer;
  right: -11px;
}
.upload-img-wrap .add-img-green {
  display: none;
}
.upload-img-wrap .add-img-gray, .upload-img-wrap .add-img-green {
  text-align: center;
  position: absolute;
  top: -12px;
  cursor: pointer;
  left: -11px;
}
.upload-img-wrap .edit-img {
  text-align: center;
  position: absolute;
  top: -12px;
  background: #ddd;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  line-height: 2;
  left: -11px;
}
.upload-img-wrap.selected-image .add-img-green {
  display: block;
}
.upload-img-wrap.selected-image .add-img-gray {
  display: none;
}

.border-dashed {
  border: 2px dashed #ddd;
}
.border-dashed.is-invalid {
  border: 2px dashed red;
}

.border-dashed input[type=file] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
}

.number-circle {
  width: 30px;
  height: 30px;
  background: #ccc;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 2;
}

.table-container {
  overflow-x: scroll;
  min-height: 250px;
}
.table-container table tr th {
  min-width: 180px;
}
.table-container table tr th:last-child {
  min-width: 10px;
}

table tr .dropleft .dropdown-toggle:before {
  content: none;
}
table tr .dropdown-menu {
  font-size: 13px;
}
table tr .dropdown-menu a:hover {
  color: #374abe;
}

.modal-custom .modal-dialog {
  max-width: 600px;
}

.modal .close {
  border: none !important;
}

.custom-checkbox2 ::before {
  border: #333 solid 2px !important;
  border-radius: 3px;
}

.custom-checkbox2 .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23374abe' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  color: #3f51c1;
}

.custom-checkbox2 .custom-control-input:checked ~ .custom-control-label::before {
  /* color: #fff; */
  border-color: #3f51c1 !important;
  background-color: #fff;
}

.custom-select {
  background-image: url("../../assets/img/Chevron_Right.svg");
}

.link:hover {
  color: #3f51c1 !important;
}

.selected-area .del {
  display: none;
  padding-right: 5px;
}
.selected-area:hover {
  color: #3f51c1 !important;
  background: #eee;
  cursor: pointer;
}
.selected-area:hover .del {
  display: inline;
}

.tag-box {
  padding: 14px 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
}

.auto-margin {
  margin: auto !important;
}

.w-80 {
  width: 80% !important;
}

.login-bg {
  height: 100%;
  background: url("../../assets/img/logn-bg.png") no-repeat center center;
  background-size: cover;
  position: relative;
}
.login-bg:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}
.login-bg .position-relative {
  z-index: 9;
}

.invalid-feedback {
  text-align: right;
}

.input-group-custom {
  position: relative;
}
.input-group-custom .m-wrap {
  border: 2px solid #ddd;
  border-radius: 5px !important;
  padding: 15px 5px 15px 66px;
  width: 100%;
  height: 52px;
}
.input-group-custom .was-validated .m-wrap:invalid,
.input-group-custom .m-wrap.is-invalid {
  border-color: #dc3545;
  padding-right: 35px;
}
.input-group-custom .invalid-feedback {
  position: absolute;
  top: 100%;
  right: 0;
}
.input-group-custom .input-group-text {
  background: none;
  border-right: 2px solid #999 !important;
  position: absolute;
  top: 14px;
  left: 4px;
  z-index: 9;
}

.is-invalid .error-feedback {
  display: block !important;
  position: absolute;
  bottom: -16px;
  right: 0;
}
.is-invalid .error-append-control {
  border-color: red !important;
}

.btn-warning {
  background: #ff9700;
  color: #fff;
}
.btn-warning:hover {
  color: #fff;
}

.login-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.required-feedback {
  display: none;
}

.required-field {
  border-color: red !important;
}
.required-field .required-feedback {
  display: block;
  color: red;
}

.option-btn {
  transform: rotate(90deg);
}

.active-item {
  background: #f2f5ff;
  color: #374abe;
}
.active-item:last-child {
  border-radius: 0 0 10px 10px;
}

.disabled-item {
  position: relative;
  cursor: not-allowed;
  background: #f2f2f2;
  color: #999;
}

.overflow-limit {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.hover-link:hover {
  background: #f2f5ff;
  color: #374abe;
}

.dropdown-menu-arrow {
  top: -25px;
  left: 100%;
  width: 0;
  height: 0;
  position: relative;
}

.dropdown-menu-arrow:before,
.dropdown-menu-arrow:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 12px 10px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
}

.dropdown-menu-arrow:after {
  bottom: -18px;
  right: -8px;
  border-bottom-color: #fff;
}

.dropdown-menu-arrow:before {
  bottom: -17px;
  right: -8px;
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

.dropdown-item:hover {
  color: #374abe;
}

.btn-default.btn-primary {
  border-color: #374abe;
  background: #374abe;
  font-weight: bold;
  font-size: 14px;
}

.ng-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999999;
  background: rgba(250, 250, 250, 0.5);
}
.ng-loader .full-screen {
  position: fixed;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #374abe;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.pagination .page-link {
  border-radius: 50% !important;
  height: 30px;
  width: 30px;
  line-height: 2;
  text-align: center;
  padding: 0px;
  margin: 0 3px;
  border-color: #fbfbfb;
}

.hoverPop {
  position: relative;
}
.hoverPop .popover-container {
  position: absolute;
  top: -20px;
  left: calc(100% + 20px);
  width: 280px;
  min-height: 80px;
  border-radius: 10px;
  z-index: 9999;
  display: none;
}
.hoverPop .popover-container:before {
  content: "";
  position: absolute;
  top: 20px;
  right: 100%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #eee;
}
.hoverPop .popover-container:after {
  content: "";
  position: absolute;
  top: 20px;
  right: calc(100% - 2px);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
}
.hoverPop:hover .popover-container {
  display: inline-block;
}

.img-close {
  position: absolute;
  top: -10px;
  right: -14px;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

ng-select.ng-invalid.is-invalid .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.edit-order {
  display: block !important;
}

.full-red-border {
  border-color: #dc3545 !important;
}

.left-red-border {
  border-color: #dc3545 !important;
}

a {
  cursor: pointer !important;
}

.login-container {
  overflow: scroll;
}

/* Auth Pages */
.auth-logo-image {
  height: 100px;
  width: auto;
  margin-bottom: 71px;
}

/* Toastr Customisation */
.ngx-toastr {
  right: 16px !important;
}

.slug-preview {
  position: relative;
  top: 0;
  display: block;
  left: 0;
}

.slug-preview p {
  text-align: left;
}

.custom-invalid-feedback p {
  text-align: right;
  font-size: 80%;
  padding-top: 3px;
}

.custom-invalid-feedback div {
  text-align: right;
  font-size: 80%;
  color: #dc3545;
  padding-top: 3px;
}

.print-window {
  padding-left: 50px;
  padding-right: 50px;
}

.print-window .print-icon, .print-window .breadcrump {
  display: none;
}

.print-window .right-area {
  width: 400px !important;
}

.print-window .left-area {
  width: 400px !important;
}

.print-window .left-area .col-lg-4 {
  width: 50% !important;
}

.print-window .table-details {
  display: inline !important;
}

.print-window .print-hidden {
  display: none;
}

.print-window .left-area h6, .print-window td span .print-window th span {
  font-size: 12px !important;
}

.today1 {
  background-color: #5cb85c !important;
  color: white !important;
}